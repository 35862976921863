<template>
  <table
    class="color_bar xmb-n4"
    :style="cssVars"
  >
    <tr v-if="name">
      <td
        colspan="100%"
        class="branded pt-0"
      >
        {{ splitName[1] }}
        <img
          src="/img/arkscore-logo-name.svg"
          height="10"
        >
        {{ splitName[3] }}
      </td>
    </tr>
    <tr>
      <td
        v-if="!hideGuides"
        class="guide"
      >
        <v-icon
          v-if="iconGuides"
          class="mr-n3"
          :size="26"
        >
          mdi-menu-down
        </v-icon>
        <span
          v-else
          class="text-uppercase text-caption"
        >
          {{ $t('low') }}
        </span>
      </td>
      <td
        v-for="(color, index) in levels"
        :key="index"
      >
        <div
          :style="'background:' + (score ? color : '#999')"
          :class="{ 'score': score == index + 1 }"
          class="d-flex align-center justify-center"
        >
          <span class="num">
            {{ score == index + 1 ? score : '&nbsp;' }}
          </span>
        </div>
      </td>
      <td
        v-if="!hideGuides"
        class="guide"
      >
        <v-icon
          v-if="iconGuides"
          class="ml-n3"
          :size="26"
        >
          mdi-menu-up
        </v-icon>
        <span
          v-else
          class="text-uppercase text-caption"
        >
          {{ $t('high') }}
        </span>
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  props: {
    score: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: 18,
    },
    iconGuides: {
      type: Boolean,
    },
    hideGuides: {
      type: Boolean,
    },
  },
  data: () => ({
    levels: [
      '#00814e',
      '#4cb648',
      '#c6d935',
      '#ffcc05',
      '#f79839',
      '#ec2024',
      '#ae1618',
    ],
  }),
  computed: {
    splitName() {
      return this.name.split(/(.*)(Arkscore)(.*)/)
    },
    cssVars() {
      return {
        '--div-height': (this.height) + "px",
        '--line-height': (this.height / 11),
        '--border-radius': (this.height / 2.5) + "px",
        '--score-size': (this.height + 4) + "px",
        '--score-height': (this.height + 10) + "px",
        '--score-color': [3,4].includes(this.score) ? '#555' : '#fff',
      }
    },
  },
}
</script>
<style lang="scss">
.color_bar {
  border-spacing: 3px;
  border-collapse: separate;
  width: 100%;

  td {
    padding: 0;
    margin: 0;
    text-align: center;
    width: 12%;

    div {
      max-height: var(--div-height);
    }

    .score {
      max-height: unset;
    }
  }

  .guide {
    width: auto;
    font-weight: 500;
    vertical-align: middle;
    color: #888;
    font-size: 1em;
  }

  .guide:first-child {
    padding-right: 5px;
  }

  .guide:last-child {
    padding-left: 5px;
  }

  .score {
    font-family: Montserrat;
    color: var(--score-color);
    width: 100%;
    font-weight: 700;
    font-size: var(--score-size);
    xline-height: var(--line-height);
    position: relative;;
    xtop: -10%;
    xmargin: 0 auto;
    background: inherit;
    border-radius: var(--border-radius);
    xpadding: 2px 0;
    border: 1px solid #fff;
    height: var(--score-height);

    .num {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .branded {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 0.9em;
    line-height: 1;
  }
}
</style>
